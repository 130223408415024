import {
BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";


import React, {Component} from 'react'
import Home from './pages/dash.js'
import DashIndex from './pages/dashindex.js'
import WordUpload from './pages/wordupload.js'
import Login from './pages/login.js'
import WordUpdate from "./pages/edit.js";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ClientHomepage, {DisplayPage} from './pages/clienthome.js'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';




class App extends Component{
  constructor(props){
    super(props)
  }




  render(){
    return(
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <BrowserRouter basename=''>
          <Routes>
            <Route path='/ab/admin/' element={<Home />}>
              <Route index element={<DashIndex />} />
              <Route path='uploads/' element = {<WordUpload />} />
              <Route path='edit/:wordid/' element = {<WordUpdate />} />
            </Route>
            <Route path='/ab/login/' element = {<Login />} />
            <Route path='/' element = {
              <ClientHomepage />
            } />
            <Route path='/:wordid/' element = {
                <DisplayPage />
              } />
            <Route path="*"  element = {
              <main className='container'>
                  <div className='d-flex justify-content-center'>
                  <div className='col-10 col-lg-4 col-md-6'>
                      <div className='card'>
                      <div className='card-body'>
                          <h5 className='card-title'>Page Not Found</h5>
                          <p> There is nothing on this page </p>    
                      </div>
                      </div>     
                  </div>
                  </div>
                  </main>
            } />
          </Routes>
        </BrowserRouter>

        <ToastContainer />
      </LocalizationProvider>
    )
  }
}




export default App;

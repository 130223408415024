import { create } from 'zustand'

const useAlert = create((set) => ({
  message: '',
  error: false,
  updateAlert: (value) => set((state) =>({ error: value['error'], message: value['message']})),
})) 


export default useAlert;


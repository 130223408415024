import React, {Component, useRef} from 'react'
import {useNavigate, NavLink, Outlet } from 'react-router-dom'
import {loadStorage, putStorage, setKeys, API_URL, addJs} from '../utils/utils'
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import '../assets/css/nucleo-icons.css'
import '../assets/css/nucleo-svg.css'
import '../assets/css/soft-ui-dashboard.css?v=1.0.7'
import SimpleSnackbar from './alert';
import useAlert from '../context/alertContext';
import {toast} from 'react-toastify';


const Load = styled(LoadingButton)({
  width: '100%',
  paddingTop: '1rem',
  paddingBottom: '1rem',
})



class _Login extends Component{

    constructor(props){
        super(props)
        this.state = {
            'question': '',
            'typing': false,
            'email': '',
            'password': '',
            'error': false,
            'message': '',
            'loading': false,
            'ready': false,
            'checked': false
        }
        this.screen = React.createRef();
    }

    componentDidMount(){
        addJs(['/assets/js/core/popper.min.js','/assets/js/core/bootstrap.min.js', '/assets/js/plugins/perfect-scrollbar.min.js','/assets/js/soft-ui-dashboard.js',
        ,])
    }

    updateEmail = (event) => {
      this.setState({'email': event.currentTarget.value})
      if(this.state.email && this.state.password){
        this.setState({'ready': true})
      }
    }
    updatePassword = (event) => {
      this.setState({'password': event.currentTarget.value})
      if(this.state.email && this.state.password){
        this.setState({'ready': true})
      }
    }

    submit = () => {
      this.setState({'loading': true})
      let _this = this;
      fetch(API_URL + '/api/token/', {'method': 'POST',
        'headers': {
          'Content-Type':'application/json',
        },
      body: JSON.stringify({"email": _this.state.email, 'password': _this.state.password})}
      ).then(response => {
        if(response.status != 200){
          response.json().then(data => {
            console.log(data)
            toast.error('Login Failed')
            this.setState({'error': true, 'message': data['detail'],'loading': false})

          })
          
        }else{
          //console.log('failed to process request')
          response.json().then(data => {
            //console.log(data);
            this.setState({'loading': false})
            setKeys(data['access'], data['refresh'])
            this.props.navigate('/ab/admin/');
          })
          
        }

      _this.setState({'loading': false})
      })
    }
    
    checked = () => {
      this.setState({'checked': !this.state.checked})
    }

  render(){
    return (
      <div>
      <SimpleSnackbar />
      <div className="container position-sticky z-index-sticky top-0">
    <div className="row">
      <div className="col-12">
        
        <nav className="navbar navbar-expand-lg blur blur-rounded top-0 z-index-3 shadow position-absolute my-3 py-2 start-0 end-0 mx-4">
          <div className="container-fluid pe-0">
            <a className="navbar-brand font-weight-bolder ms-lg-0 ms-3 " href="/">
              NorfK Panel
            </a>
            <button className="navbar-toggler shadow-none ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon mt-2">
                <span className="navbar-toggler-bar bar1"></span>
                <span className="navbar-toggler-bar bar2"></span>
                <span className="navbar-toggler-bar bar3"></span>
              </span>
            </button>
            <div className="collapse navbar-collapse" id="navigation">

            </div>
          </div>
        </nav>
        
      </div>
    </div>
  </div>
  <main className="main-content  mt-0">
    <section>
      <div className="page-header min-vh-75">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-5 col-md-6 d-flex flex-column mx-auto">
              <div className="card card-plain mt-8">
                <div className="card-header pb-0 text-left bg-transparent">
                  <h3 className="font-weight-bolder text-info text-gradient">Welcome back</h3>
                  <p className="mb-0">Enter your email and password to sign in</p>
                </div>
                <div className="card-body">
                  <form role="form">
                    <label>Email</label>
                    <div className="mb-3">
                      <input onInput = {this.updateEmail} type="email" className="form-control sl-email" placeholder="Email" aria-label="Email" aria-describedby="email-addon" />
                    </div>
                    <label>Password</label>
                    <div className="mb-3">
                      <input onInput = {this.updatePassword} type="password" className="form-control sl-password" placeholder="Password" aria-label="Password" aria-describedby="password-addon" />
                    </div>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" id="rememberMe" checked={this.state.checked} onChange = {this.checked} />
                      <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
                    </div>
                    <div className="text-center">
                      {this.state.loading ? <button className="btn bg-gradient-info w-100 mt-4 mb-0" type="button" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading...</button> : <button onClick = {this.submit} type="button" className="btn bg-gradient-info w-100 mt-4 mb-0 sl-login">Sign in</button> }
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="oblique position-absolute top-0 h-100 d-md-block d-none me-n8">
                <div className="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6" style={{ backgroundImage: `url(${require('../assets/img/curved-images/curved-10.jpg')})` }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  
  </div>
  )
}

}

function Login(props){
  let navigate = useNavigate();
  let dispatch = useAlert(state => state.updateAlert)
  return <_Login navigate = {navigate} dispatch = {dispatch} {...props} />
}
export default Login;

const API_URL = process.env.REACT_APP_API_URI;

function addJs(links) {
  var body = document.querySelector('body');
  var loadScript = function(url) {
    return new Promise(function(resolve, reject) {
      var script = document.createElement('script');
      script.src = process.env.PUBLIC_URL + url;
      script.type = 'text/javascript';
      script.onload = function() {
        resolve();
      };
      script.onerror = function() {
        reject(new Error('Failed to load script: ' + url));
      };
      body.appendChild(script);
    });
  };

  var loadScriptsSequentially = function(links) {
    if (links.length === 0) {
      return Promise.resolve();
    }
    var url = links.shift();
    return loadScript(url)
      .then(function() {
        return loadScriptsSequentially(links);
      });
  };

  return loadScriptsSequentially(links);
}


function makeRequest(url, options = {}) {
  const access_token = localStorage.getItem('access_token');
  const refresh_token = localStorage.getItem('refresh_token');
  if (access_token) {
    options.headers = options.headers || {};
    options.headers.Authorization = `Bearer ${access_token}`;
  } else {
    console.log('Access token not found. Redirecting to login page.');
    window.location.href = '/ab/login';
    return;
  }
  return fetch(url, options)
    .then(response => {

      if (response.status === 401) {
        console.log('Access token expired. Refreshing token.');

        return fetch(API_URL + '/api/refreshToken/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({'refresh': refresh_token })
        })
          .then(response => response.json())
          .then(data => {
            if (data.access) {
              console.log('Token refreshed successfully. Retrying request.');
              localStorage.setItem('access_token', data.access);
              options.headers.Authorization = `Bearer ${data.access_token}`;
              return makeRequest(url, options);
            } else {
              console.log('Refresh token expired. Redirecting to login page.');
              window.location.href = '/ab/login';
              return false;
            }
          })
          .catch(error => {
            console.error(error);
            return false;
          });
      }else if (response.status === 413) {
        console.error('Media too large');
        throw new Error('Media too large')
      }else if (response.status > 499){
        console.log(response)
        return false
      }else  {
        return response;
      }
    })
    .catch(error => {
      console.error(error);
      return false;
    });
}


function setKeys(access_token, refresh_token){
  localStorage.setItem('access_token', access_token)
  localStorage.setItem('refresh_token', refresh_token)
}





function displayAlert(alertType, alertMessage){
  let alert_box = document.querySelector('.alert-box')
  if(alertType === 'info'){
    alert_box.innerHTML = `
    <div class='px-2 information alert-dismissible fade show'>
      <i class='bi bi-megaphone me-2 text-success'></i>
      <span class='text-success me-2'>${alertMessage}</span>
      <i class='bi bi-x text-dark' data-bs-dismiss='information'></i>
    </div>
    `
  }else if(alertType === 'error'){
    alert_box.innerHTML = `
    <div class='px-2 information alert-dismissible fade show'>
      <i class='bi bi-wifi-off me-2 text-danger'></i>
      <span class='text-danger me-2'>${alertMessage}</span>
      <i class='bi bi-x text-dark' data-bs-dismiss='information'></i>
    </div>
    `
  }

  alert_box.classList.remove('close')
  alert_box.addEventListener('click', function(){
    this.classList.add('close');
  })
}


function loadStorage(){
  let items = window.sessionStorage.getItem('payload')
  if(!items) return false;

  let basket = JSON.parse(items)
  return basket
}


function putStorage(payload){
  let items = JSON.stringify(payload)
  window.sessionStorage.setItem('payload', items)
  return true
}




// Function to calculate the value
function calculateValue(words) {
  return words / 1000 * 1;
}

// Main function
function processCSVFile(file) {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = () => {
      const content = reader.result;
      const wordCount = content.split(/\s+/).length;
      const wordCountInThousands = wordCount / 1000;
      resolve({ wordCount, wordCountInThousands });
    };

    reader.onerror = () => {
      reject(reader.error);
    };

    reader.readAsText(file);
  });
}






export default calculateValue;
export {displayAlert, loadStorage, putStorage, makeRequest, processCSVFile, setKeys, API_URL, addJs}

import React, { Component } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { makeRequest, API_URL } from '../utils/utils.js';
import 'react-loading-skeleton/dist/skeleton.css';
import { DataGrid } from '@mui/x-data-grid';
import { toast } from 'react-toastify';

class BDashIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      start_date: '2022-01-01',
      end_date: '2023-12-01',
      datatableColumns: [
        { 
          field: 'id', 
          headerName: 'ID', 
          width: 150, 
          renderCell: (params) => (
            <Link to={`edit/${params.row.id}`} style={{ cursor: 'pointer' }}>{params.row.id}</Link>
          ) 
        },
        { field: 'english', headerName: 'English', width: 250 },
        { field: 'norfk', headerName: "Norf'k", width: 250 },
        { field: 'alt_spelling', headerName: 'Alt Spelling', width: 250 },
        { field: 'part_of_speech', headerName: 'Part of Speech', width: 250 },
        { field: 'upload_date', headerName: 'Date', width: 250 },
        { 
          field: 'highlight', 
          headerName: 'Highlight', 
          width: 150,
          renderCell: (params) => (
            <input
              type="checkbox"
              checked={params.row.highlight}
              onChange={() => this.handleHighlightChange(params.row.id)}
            />
          ) 
        },
        { 
          field: 'delete', 
          headerName: 'Operation', 
          renderCell: (params) => (
            <div
              onClick={() => this.handleDelete(params.row.id)}
              style={{ cursor: 'pointer' }}
            >Delete</div>
          )
        }
      ],
      knowledgebase: [],
    };
  }

  handleDelete = (id) => {
    let _this = this;
    console.log(id);
    makeRequest(API_URL + '/api/words/delete/', {
      method: 'DELETE',
      body: JSON.stringify({ word_id: id }),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {
      if (res.status !== 200) {
        console.log('handle error here');
      } else {
        res.json().then(data => {
          toast.info('Word deleted');
          _this.loadWords();
        });
      }
    });
  }

  handleHighlightChange = (id) => {
    this.setState(prevState => {
      const updatedKnowledgebase = prevState.knowledgebase.map(word => ({
        ...word,
        highlight: word.id === id ? !word.highlight : false,
      }));
      return { knowledgebase: updatedKnowledgebase };
    });
  }

  componentDidMount() {
    this.loadWords();
  }

  loadWords = () => {
    console.log('load words called');
    makeRequest(API_URL + '/api/words/').then(response => {
      if (response.status !== 200) {
        console.log('handle error here');
      } else {
        response.json().then(data => {
          console.log(data);
          this.setState({ knowledgebase: data });
        });
      }
    });
  }

  getDate = (event) => {
    let dateString = event['$d'];
    console.log(dateString);
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  render() {
    return (
      <div className="container-fluid py-4">
        <div className="row my-4">
          <div className="col-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="row">
                  <div className="col-lg-6 col-7">
                    <h6>Words</h6>
                  </div>
                </div>
              </div>
              <div className="card-body px-0 pb-2">
                <div className="table-responsive">
                  <DataGrid
                    className="table border-none"
                    style={{ minHeight: '450px' }}
                    columns={this.state.datatableColumns}
                    rows={this.state.knowledgebase}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function DashIndex(props) {
  let navigate = useNavigate();
  return <BDashIndex {...props} navigate={navigate} />;
}

export default DashIndex;

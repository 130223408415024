// ClientHomepage.js
import React, { useEffect, useState } from "react";
import ImageCarousel from "../components/slider/image";
import "./clienthome.css"; // Import the CSS file
import { toast } from "react-toastify";
export default function ClientHomepage() {
  const [word, setWord] = useState("");
  const [search_term, setSearchTerm] = useState("");
  const [wordList, setWordList] = useState([]);
  const [audio, setAudio] = useState(null);

  const [loading, setLoading] = useState(true);

  function prepareAudio(data) {
    setAudio(null);
    console.log(data);
    if(!(data.file_set )){
      return;
    }

    let audio_file = data.file_set.filter((file) =>
      file.file_type === 'audio'
    )[0];

    console.log(audio_file);
    
    if (audio_file) {
      setAudio(audio_file.content);
    }
  }

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URI + "/api/client/highlight/").then(
      (response) => {
        console.log(response);
        if (response.status !== 200) {
          console.log("error herer");
        } else {
          response.json().then((data) => {
            console.log(data);
            setWord(data);
            prepareAudio(data);
            setLoading(false);
          });
        }
      }
    );
  }, []);

  const select = (wordid) => {
    setAudio(null);

    fetch(process.env.REACT_APP_API_URI + "/api/client/word/", {
      method: "POST",
      body: JSON.stringify({ word_id: wordid }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log("handle error here");
        } else {
          response.json().then((data) => {
            console.log(data);
            prepareAudio(data);
            setWord(data);
            document.getElementById("channel").scrollIntoView({
              behavior: "smooth", // or 'auto' for instant scrolling
              block: "start", // aligns the top of the element to the top of the viewport
            });
          });
        }
      })
      .then(() => {
        setWordList([]);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const search = () => {
    console.log(process.env.REACT_APP_API_URI);
    setLoading(true);
    setAudio(null);
    console.log(search_term);
    if (search_term) {
      fetch(process.env.REACT_APP_API_URI + "/api/client/search/", {
        method: "POST",
        body: JSON.stringify({ word: search_term.toLowerCase() }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            response.json().then((data) => {
              console.log(data);
              setWord(data[0]);
              document.getElementById("channel").scrollIntoView({
                behavior: "smooth", // or 'auto' for instant scrolling
                block: "start", // aligns the top of the element to the top of the viewport
              });
            });
          } else if (response.status === 406) {
            toast.info("Word not found!");
          } else {
          }
        })
        .then(() => {
          setLoading(false);
          setWordList([]);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  function browseWord(search_term, button) {
    fetch(process.env.REACT_APP_API_URI + "/api/client/search/", {
      method: "POST",
      body: JSON.stringify({ word: search_term.toLowerCase() }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          response.json().then((data) => {
            console.log(data);
            setWordList(data);
          });
        } else if (response.status === 406) {
          if (button) {
            toast.info("Word not found!");
          }
        } else {
        }
      })
      .then(() => {
        setLoading(false);
        setWordList([]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <>
      {/* Banner */}
      <div className="banner">
        <div className="banner-main">
          <div>
            <h1>Norf'k Dictionary</h1>
            <h2>Just the first few letters in Norf'k or English</h2>
            <div className="banner-search">
              <input
                onInput={(e) => {
                  setSearchTerm(e.target.value);
                  browseWord(e.target.value, false);
                }}
                type="text"
                placeholder="start your word search"
              />
              <button onClick={search}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2em"
                  height="2em"
                  viewBox="0 0 24 24"
                >
                  <g fill="none" stroke="currentColor" strokeWidth="2">
                    <circle cx="11" cy="11" r="7" />
                    <path
                      strokeLinecap="round"
                      d="M11 8a3 3 0 0 0-3 3m12 9l-3-3"
                    />
                  </g>
                </svg>
              </button>

              {wordList.length > 0 && (
                <div className="search-container my-scroll">
                  {wordList.map((word, key) => (
                    <div
                      onClick={() => {
                        console.log(word.id);
                        select(word.id);
                      }}
                      style={{
                        "border-bottom": "1px dashed #6ba9b3",
                        "margin-bottom": "3px",
                      }}
                      key={key}
                    >{`${word.norfk} : ${word.english}`}</div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div>
            <img src="/images/pine.avif" alt="Pine" className="banner-img" />
          </div>
        </div>
      </div>

      {/* Content Area */}
      <div
        id="channel"
        className="content-area"
        style={{ marginTop: "4rem", marginBottom: "4rem" }}
      >
        {!loading ? (
          <div className="content">
            <div>
              <div className="word">
                <div className="text-content-header">
                  <div style={{ fontSize: "4rem", color: '#00C8E6' }}>{word.norfk}</div>
                </div>
                {audio && (
                  <div className="text-content-icon ms-3">
                    <svg
                      onClick={() => {
                        console.log(audio);
                        let player = new Audio(audio);
                        player.play();
                        console.log("playing the audio file for you now.");
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="70"
                      height="150"
                      viewBox="0 0 24 24"
                    >
                      <g fill="none" stroke="#00C8E6" strokeWidth="1.5">
                        <path d="M1 13.857v-3.714a2 2 0 0 1 2-2h2.9a1 1 0 0 0 .55-.165l6-3.956a1 1 0 0 1 1.55.835v14.286a1 1 0 0 1-1.55.835l-6-3.956a1 1 0 0 0-.55-.165H3a2 2 0 0 1-2-2Z" />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17.5 7.5S19 9 19 11.5s-1.5 4-1.5 4m3-11S23 7 23 11.5s-2.5 7-2.5 7"
                        />
                      </g>
                    </svg>
                  </div>
                )}
              </div>
              <div style={{marginBottom: '2rem'}}>{word.part_of_speech}</div>

              <div className="text-content">
                <h3>English</h3>
                <div>{word.english}</div>
              </div>

              <div className="text-content">
                <h3>Norf'k Usage</h3>
                <div>{word.norfk_usage}</div>
              </div>

              <div className="text-content">
                <h3>English Usage</h3>
                <div>{word.english_usage}</div>
              </div>
            </div>
            <div className="carousel-container">
              {word.file_set && <ImageCarousel images={word.file_set.filter(entry => entry.file_type === 'image').map((entry) => entry.content)} /> }
            </div>
          </div>
        ) : (
          <LoadingPage />
        )}
      </div>

      {/* Footer Area */}
      <div className="footer">
        <span>Website Contact: dictionary2899@gmail.com</span>
      </div>
    </>
  );
}

function LoadingPage() {
  return <>Loading ...</>;
}

export function DisplayPage() {
  return <>Display</>;
}

import { create } from 'zustand'

const useFiles = create((set) => ({
  files: [],
  updateFiles: (value) => {
    console.log('updating the files');
    return set((state) =>({files: value}));
  },
})) 


export default useFiles;


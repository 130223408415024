import React, { useRef, useState, useEffect } from 'react';
import './image.css'; // Import the CSS for styling

const ImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef();



  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  const scrollToIndex = (index) => {
    if (carouselRef.current) {
      const carouselWidth = carouselRef.current.clientWidth;
      const imageWidth = carouselRef.current.children[0].clientWidth;
      const scrollPosition = (index * imageWidth) - (carouselWidth / 2) + (imageWidth / 2);
      carouselRef.current.scrollTo({ left: scrollPosition, behavior: 'smooth' });
    }
  };

  return (
    <div className="carousel">
      <div className="carousel-inner">
        <div className="carousel-images my-scroll-hidden"  ref={carouselRef}>
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index + 1}`}
              className={`carousel-image ${index === currentIndex ? 'active' : ''}`}
            />
          ))}
        </div>
      </div>
      <div className="carousel-dots">
        {images.map((_, index) => (
          <span
            key={index}
            className={`dot`}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default ImageCarousel;

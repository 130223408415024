import React, { Component } from "react";
import { makeRequest, API_URL } from "../utils/utils.js";
import "react-loading-skeleton/dist/skeleton.css";
import AlertDialogSlide from "./dialog.jsx";
import useAlert from "../context/alertContext.js";
import MyDropzone from "../components/dropzone.js";
import { useParams } from "react-router-dom";
import { TextField } from "@mui/material";
import ImageCarousel from "../components/imagesCarousel.js";
import useFiles from "../context/fileContext.js";
import {toast} from 'react-toastify';

class WordUploadWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      csv_loading: false,
      english: "",
      norfk: "",
      alt_spelling: "",
      part_of_speech: "",
      english_usage: "",
      word_loading: false,
      word_id: "",
      audio_uploading: false,
      norfk_usage: "",
      show_alert: false,
      message: "",
      files: [],
      file_set: []
    };

    this.csv_file = React.createRef();
    this.document_file = React.createRef();
  }


  componentDidMount() {
  }




  updateEntry = (event) => {
    let widget = event.currentTarget;
    let key = widget.getAttribute("data-key");
    let value = widget.value;

    let payload = {};
    payload[key] = value;
    this.setState(payload);
    //console.log(this.state);
  };

  submitCSV = (event) => {
    event.preventDefault();

    let _this = this;
    let file = this.csv_file.current.files[0];
    console.log(file);
    if (!file) {
      toast("File must be selected" );
      return;
    }

    let formdata = new FormData();
    formdata.append("document", file);

    _this.setState({ csv_loading: true });
    makeRequest(API_URL + "/api/words/csv_upload/", {
      method: "POST",
      body: formdata,
    }).then((response) => {
      if (response.status !== 200) {
        if (response.status === 406) {
          response.json().then((data) => {
            toast(data["message"]);
          });
        }

        _this.setState({ csv_loading: false });
      } else {
        response.json().then((data) => {
          if (data.skipped_words.length > 0) {
            let skipped = "";
            for (var i in data.skipped_words) {
              skipped += `${data.skipped_words[i]}, `;
            }
            _this.setState({
              show_alert: true,
              message: `The following words were skipped: ${skipped}`,
            });
          }
          toast("Words added to document.");
          _this.setState({ csv_loading: false });
        });
      }
    });

  };


  validateState = (state)  => {
    if (
        state.english.trim() === "" ||
        state.norfk.trim() === "" ||
        state.norfk_usage.trim() === "") {
        return false;
    }
    return true;
}


  submitWord = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    let _this = this;

    let ready = this.validateState(this.state);
    if(!ready){
      toast( 'All the fields must be populated. The Images section is optional.')
      return;
    }
    
    const formData = new FormData();
    formData.append("alt_spelling", this.state.alt_spelling);
    formData.append("english", this.state.english);
    formData.append("norfk", this.state.norfk);
    formData.append("english_usage", this.state.english_usage);
    formData.append("part_of_speech", this.state.part_of_speech);
    formData.append("norfk_usage", this.state.norfk_usage);

    for(var i in this.props.files){
      formData.append('file_set', this.props.files[i])
    }

    console.log([...formData]); // Log the FormData object for verification

    this.setState({ word_loading: true });

    makeRequest(API_URL + "/api/words/", {
      method: "POST",
      body: formData, // Use the FormData object as the body
    })
      .then((response) => {
        if (!response.ok) {
          console.error("Failed to save data to the database");
          _this.setState({ word_loading: false });
        } else {
          console.log("Data saved to the database");
          _this.props.updateFiles([])
          _this.setState({ word_loading: false, norfk: '', english: '', english_usage: '', norfk_usage: '', part_of_speech: '', alt_spelling: '' });
          toast( "Data saved to the database",
          );
        }
      })
      .catch((error) => {
        console.error("Error occurred:", error);
        _this.setState({ word_loading: false });
      });
  };

  render() {
    return (
      <div className="container-fluid py-4">
        <AlertDialogSlide
          isOpen={this.state.show_alert}
          message={this.state.message}
        />
        <div className="row justify-content-center align-items-center mb-2">
        <div className="col-12">
            <div className="card mb-4 p-2">
              <div className="card-header pb-0">
                <h6>CSV Upload</h6>
              </div>
              <div className="card-body px-0 pt-0 pb-2">
                <form role="form" onSubmit = {this.submitCSV}>
                  <label>File</label>

                  <div className="mb-3">
                    <input
                      required = {true}
                      ref={this.csv_file}
                      accept ='.csv,'
                      type="file"
                      className="form-control sl-csv-input"
                      placeholder="Input Field"
                      aria-label="Input Field"
                      
                      aria-describedby="text-addon"
                    />
                  </div>

                  <div className="text-center">
                    {this.state.csv_loading ? (
                      <button
                        className="btn w-100 btn-gradient-info btn-sm mb-2"
                        type="button"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm me-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading...
                      </button>
                    ) : (
                      <button
                        
                        type="submit"
                        className="btn bg-gradient-info w-100 mt-4 mb-0 sl-upload-csv"
                      >
                        Upload
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="col-12 my-3">
            <div className="col-12">
              <div className="card mb-4 p-2">
                <div className="card-header">
                  <h6>Upload a New Word</h6>
                </div>
                <div className="card-body px-0 pt-0 pb-2">
                  <form role="form" onSubmit={this.submitWord}>
                    <div className="row align-items-center justify-content-between">
                      <div className="mb-3 col-12 col-lg-5 col-md-5">
                        <label>English</label>
                        <input
                          required = {true}
                          onInput={this.updateEntry}
                          data-key="english"
                          value={this.state.english}
                          type="text"
                          className="form-control sl-english"
                          placeholder="English"
                          aria-label="English"
                          aria-describedby="text-addon"
                          name='english'
                        />
                      </div>

                      <div className="mb-3 col-12 col-lg-5 col-md-5">
                        <label>Norf'k</label>
                        <input
                          required = {true}
                          onInput={this.updateEntry}
                          data-key="norfk"
                          value={this.state.norfk}
                          type="text"
                          className="form-control sl-nork"
                          placeholder="Norf'k"
                          aria-label="Norfk"
                          aria-describedby="text-addon"
                        />
                      </div>

                      <div className="mb-3 col-12 col-lg-5 col-md-5">
                        <label>Alternate Spelling</label>
                        <input
                          
                          onInput={this.updateEntry}
                          data-key="alt_spelling"
                          value={this.state.alt_spelling}
                          type="text"
                          className="form-control sl-alt-spell"
                          placeholder="Alternate Spelling"
                          aria-label="Alternate Spelling"
                          aria-describedby="text-addon"
                          name='alt_spelling'
                        />
                      </div>

                      <div className="mb-3 col-12 col-lg-5 col-md-5">
                        <label>Part of Speech</label>
                        <input
                          
                          onInput={this.updateEntry}
                          data-key="part_of_speech"
                          value={this.state.part_of_speech}
                          type="text"
                          className="form-control sl-part-of-speech"
                          placeholder="Part of Speech"
                          aria-label="Part of Speech"
                          aria-describedby="text-addon"
                        />
                      </div>
                    </div>

                    <label>English Usage</label>
                    <div className="mb-3">
                      <textarea
                        
                        rows={5}
                        onInput={this.updateEntry}
                        data-key="english_usage"
                        style={{ resize: "none" }}
                        className="form-control sl-usage"
                        placeholder="Usage"
                        aria-label="Input Field"
                        aria-describedby="text-addon"
                        value={this.state.english_usage}
                      />
                    </div>

                    <label>Norf'K Usage</label>
                    <div className="mb-3">
                      <textarea
                        required={true}
                        rows={5}
                        onInput={this.updateEntry}
                        data-key="norfk_usage"
                        style={{ resize: "none" }}
                        className="form-control sl-usage"
                        placeholder="Usage"
                        aria-label="Input Field"
                        aria-describedby="text-addon"
                        value={this.state.norfk_usage}
                      />
                    </div>

                    <div className="d-flex align-items-center justify-content-center">
                      <div className="col-12 col-lg-8 col-md-8">
                        <ImageCarousel images={this.state.file_set} />
                      </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-center">
                      <div className="col-12 col-lg-8 col-md-8">
                        <MyDropzone      />
                      </div>
                    </div>

                    <div className="text-center">
                      {this.state.word_loading ? (
                        <button
                          className="btn w-100 btn-gradient-info btn-sm mb-2"
                          type="button"
                          disabled
                        >
                          <span
                            className="spinner-border spinner-border-sm me-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Loading...
                        </button>
                      ) : (
                        <button
                          

                          type="submit"
                          className="btn bg-gradient-success w-100 mt-4 mb-0 sl-upload-word"
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          
        </div>
      </div>
    );
  }
}

function WordUpload(props) {
  let dispatch = useAlert((state) => state.updateAlert);
  let { wordid } = useParams();
  let files = useFiles((state) =>  state.files)
  let updateFiles = useFiles((state) =>  state.updateFiles)


  return <WordUploadWindow {...props} updateFiles = {updateFiles} files = {files} word_id={wordid} dispatch={dispatch} />;
}

export default WordUpload;

import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import useFiles from "../context/fileContext";

export default function MyDropzone() {
  let files = useFiles((state) => state.files);
  let updateFiles = useFiles((state) => state.updateFiles);

  useEffect(function(){
    console.log('running')
    console.log(files)
  }, [files, ])



  const onDrop = useCallback(
    (acceptedFiles) => {
      const newFiles = [...files, ...acceptedFiles];
      updateFiles(newFiles);
    },
    [files, updateFiles]
  );

  const removeFile = useCallback(
    (fileName) => {
      const newFiles = files.filter((file) => file.path !== fileName);
      updateFiles(newFiles);
    },
    [files, updateFiles]
  );

  const clearFiles = useCallback(() => {
    updateFiles([]);
  }, [updateFiles]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
      "audio/mpeg": [".mp3"],
      "audio/aac": [".aac"],
    },
  });

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "200px",
          borderRadius: "15px",
          textAlign: "center",
          border: "1px dashed #ccc",
          width: "100%",
        }}
      >
        <div
          {...getRootProps()}
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>+ Click or Drop Media Files here</p>
          )}
        </div>
      </div>

      <div style={{ marginTop: "10px" }}>
        <ul>
          {files.length > 0 &&
            files.map((file, id) => (
              <div
                key={id}
                className="d-flex justify-content-between align-items-center"
              >
                <li className="col-8" key={file.path}>
                  <small> {file.path} </small>
                </li>
                <button
                  className="btn btn-sm btn-outline-secondary col-3"
                  onClick={() => removeFile(file.path)}
                  style={{ marginLeft: "10px" }}
                >
                  Remove
                </button>
              </div>
            ))}
        </ul>
        {files.length > 0 && (
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={clearFiles}
            style={{ marginTop: "10px" }}
          >
            Clear
          </button>
        )}
      </div>
    </>
  );
}

import React, { Component } from "react";
import { makeRequest, API_URL } from "../utils/utils.js";
import "react-loading-skeleton/dist/skeleton.css";
import AlertDialogSlide from "./dialog.jsx";
import useFiles from "../context/fileContext.js";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

class WordUploadWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      csv_loading: false,
      english: "",
      norfk: "",
      alt_spelling: "",
      part_of_speech: "",
      english_usage: "",
      word_loading: false,
      highlight: false,
      word_id: "",
      audio_uploading: false,
      norfk_usage: "",
      show_alert: false,
      message: "",
      files: [],
      file_set: [],
    };

    this.csv_file = React.createRef();
    this.document_file = React.createRef();
  }

  componentDidMount() {
    this.loadWords();
    this.props.updateFiles([]);
  }

  loadWords = () => {
    let _this = this;
    //console.log('load words called')
    makeRequest(API_URL + `/api/words/${_this.props.word_id}/one_word/`).then(
      (response) => {
        if (response.status != 200) {
          //console.log('handle error here')
        } else {
          response.json().then((data) => {
            console.log(data);
            this.setState({ ...data });
          });
        }
      }
    );
  };

  updateEntry = (event) => {
    let widget = event.currentTarget;
    let key = widget.getAttribute("data-key");
    let value = widget.value;

    let payload = {};
    payload[key] = value;
    this.setState(payload);
    ////console.log(this.state);
  };



  updateFiles = (event) => {
    console.log("Running the application.");
    const maxSize = 1 * 1024 * 1024; // 1MB in bytes
    let files = Array.from(event.currentTarget.files);
    const totalSize = files.reduce((acc, file) => acc + file.size, 0);
  
    if (totalSize > maxSize) {
      toast.error('Total file size exceeds 1MB. Please upload smaller files.');
      return; // Prevent further processing
    }
  
    this.setState({ files: files });
    console.log(this.state.images);
  };



  submitWord = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    const formData = new FormData();
    formData.append("alt_spelling", this.state.alt_spelling);
    formData.append("english", this.state.english);
    formData.append("norfk", this.state.norfk);
    formData.append("english_usage", this.state.english_usage);
    formData.append("part_of_speech", this.state.part_of_speech);
    formData.append("norfk_usage", this.state.norfk_usage);
    formData.append("highlight", this.state.highlight);

    if (this.state.files.length > 0) {
      this.state.files.map((entry) => {
        console.log(entry);
        formData.append("files", entry);
      });
    }

    console.log(formData);

    console.log(this.state);
    console.log([...formData]); // Log the FormData object for verification

    this.setState({ word_loading: true });

    makeRequest(API_URL + `/api/words/${this.props.word_id}/update_word/`, {
      method: "PUT",
      body: formData, // Use the FormData object as the body
    })
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          console.error("Failed to save data to the database");
          this.setState({ word_loading: false });
        } else {
          //console.log("Data saved to the database");
          this.setState({ word_loading: false });
          response.json().then((data) => {
            this.setState({ ...data });
          });
          toast.info("Data saved to the database");
        }
      })
      .catch((error) => {
        console.error("Error occurred:", error);
        this.setState({ word_loading: false });
      });
  };


  deleteFile = (fileid, parent) => {
    makeRequest(API_URL + `/api/words/delete_file/`, {
      method: "DELETE",
      'headers': {'Content-Type': 'application/json'},
      body: JSON.stringify({'file_id': fileid}), // Use the FormData object as the body
    })
      .then((response) => {
        if (!response.ok) {
          console.log(response);
          if(response.status === 413){
            console.log('Media size too large.')
          }
          console.error('Failed to delete file')
        } else {
          toast.info("File Deleted");
          parent.classList.add('deleted');
        }
      })
      .catch((error) => {
        console.error("Error occurred:", error);
      });
  }

  removeFile = (fileName) => {

    console.log(fileName);
    
    this.setState((prevState) => ({
      ...prevState,
      files: prevState.files.filter((file) => file.name !== fileName),
    }));
  };

  render() {
    return (
      <div className="container-fluid py-4">
        <AlertDialogSlide
          isOpen={this.state.show_alert}
          message={this.state.message}
        />
        <div className="row justify-content-center align-items-center mb-2">
          <div className="col-12 mb-3">
            <div className="col-12">
              <div className="card mb-4 p-2">
                <div className="card-header">
                  <h6>Edit Word ID: {this.props.word_id}</h6>
                </div>
                <div className="card-body px-0 pt-0 pb-2">
                  <form role="form">
                    <div className="row align-items-center justify-content-between">
                      <div className="mb-3 col-12 col-lg-5 col-md-5">
                        <label>English</label>
                        <input
                          onInput={this.updateEntry}
                          data-key="english"
                          value={this.state.english}
                          type="text"
                          className="form-control sl-english"
                          placeholder="English"
                          aria-label="English"
                          aria-describedby="text-addon"
                        />
                      </div>

                      <div className="mb-3 col-12 col-lg-5 col-md-5">
                        <label>Norf'k</label>
                        <input
                          onInput={this.updateEntry}
                          data-key="norfk"
                          value={this.state.norfk}
                          type="text"
                          className="form-control sl-nork"
                          placeholder="Norf'k"
                          aria-label="Norfk"
                          aria-describedby="text-addon"
                        />
                      </div>

                      <div className="mb-3 col-12 col-lg-5 col-md-5">
                        <label>Alternate Spelling</label>
                        <input
                          onInput={this.updateEntry}
                          data-key="alt_spelling"
                          value={this.state.alt_spelling}
                          type="text"
                          className="form-control sl-alt-spell"
                          placeholder="Alternate Spelling"
                          aria-label="Alternate Spelling"
                          aria-describedby="text-addon"
                        />
                      </div>

                      <div className="mb-3 col-12 col-lg-5 col-md-5">
                        <label>Part of Speech</label>
                        <input
                          onInput={this.updateEntry}
                          data-key="part_of_speech"
                          value={this.state.part_of_speech}
                          type="text"
                          className="form-control sl-part-of-speech"
                          placeholder="Part of Speech"
                          aria-label="Part of Speech"
                          aria-describedby="text-addon"
                        />
                      </div>

                      <div
                        className="mb-3 col-12 col-lg-5 col-md-5 my-4"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <input
                          onInput={() => {
                            this.setState({ highlight: !this.state.highlight });
                          }}
                          data-key="hightlight"
                          value={this.state.highlight}
                          checked={this.state.highlight}
                          type="checkbox"
                          className="sl-hightlight"
                          aria-describedby="text-addon"
                        />
                        <span style={{ marginLeft: "10px" }}>HightLight</span>
                      </div>
                    </div>

                    <label>English Usage</label>
                    <div className="mb-3">
                      <textarea
                        rows={5}
                        onInput={this.updateEntry}
                        data-key="english_usage"
                        style={{ resize: "none" }}
                        className="form-control sl-usage"
                        placeholder="Usage"
                        aria-label="Chatbot Name"
                        aria-describedby="text-addon"
                        value={this.state.english_usage}
                      />
                    </div>

                    <label>Norf'K Usage</label>
                    <div className="mb-3">
                      <textarea
                        rows={5}
                        onInput={this.updateEntry}
                        data-key="norfk_usage"
                        style={{ resize: "none" }}
                        className="form-control sl-usage"
                        placeholder="Usage"
                        aria-label="Chatbot Name"
                        aria-describedby="text-addon"
                        value={this.state.norfk_usage}
                      />
                    </div>

                    <div className="d-flex align-items-center justify-content-center">
                      <div className="col-12 col-lg-8 col-md-8">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            overflowX: "auto",
                          }}
                        >
                          {this.state.file_set
                            .filter((entry) => entry.type === "image")
                            .map((image, key) => (
                              <div key={key} style={{ position: "relative" }}>
                                <svg
                                  onClick={(e) => {
                                    console.log(image);
                                    this.deleteFile(image.id, e.currentTarget.parentElement)
                                    
                                  }}
                                  style={{
                                    position: "absolute",
                                    top: "2px",
                                    right: "10px",
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="1.5em"
                                  height="1.5em"
                                  viewBox="0 0 32 32"
                                >
                                  <path
                                    fill="red"
                                    d="M16 2C8.2 2 2 8.2 2 16s6.2 14 14 14s14-6.2 14-14S23.8 2 16 2m5.4 21L16 17.6L10.6 23L9 21.4l5.4-5.4L9 10.6L10.6 9l5.4 5.4L21.4 9l1.6 1.6l-5.4 5.4l5.4 5.4z"
                                  />
                                </svg>
                                <img
                                  src={image.url}
                                  style={{
                                    width: "150px",
                                    height: "150px",
                                    borderRadius: "10px",
                                    marginRight: "10px",
                                    marginBottom: "10px",
                                  }}
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-center">
                      <div className="col-12 col-lg-8 col-md-8">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            overflowX: "auto",
                          }}
                        >
                          {this.state.file_set
                            .filter((entry) => entry.type === "audio")
                            .map((audio, key) => (
                              <div
                                key={key}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <audio src={audio.url} controls />
                                <svg
                                  onClick={(e) => {
                                    console.log(audio);
                                    this.deleteFile(audio.id, e.currentTarget.parentElement)
                                    
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="1.5em"
                                  height="1.5em"
                                  viewBox="0 0 32 32"
                                >
                                  <path
                                    fill="red"
                                    d="M16 2C8.2 2 2 8.2 2 16s6.2 14 14 14s14-6.2 14-14S23.8 2 16 2m5.4 21L16 17.6L10.6 23L9 21.4l5.4-5.4L9 10.6L10.6 9l5.4 5.4L21.4 9l1.6 1.6l-5.4 5.4l5.4 5.4z"
                                  />
                                </svg>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>

                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ marginTop: "20px" }}
                    >
                      <div className="col-12 col-lg-8 col-md-8">
                        <label
                          htmlFor="file"
                          id="label-file"
                          style={{
                            fontSize: "16px",
                            border: "1px dashed #ccc",
                            width: "100%",
                            padding: "1rem 2rem",
                            textAlign: "center",
                            borderRadius: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <span style={{ marginRight: "10px" }}>
                            Add Images and Audio
                          </span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2em"
                            height="2em"
                            viewBox="0 0 1024 1024"
                          >
                            <path
                              fill="currentColor"
                              d="M544 864V672h128L512 480L352 672h128v192H320v-1.6c-5.376.32-10.496 1.6-16 1.6A240 240 0 0 1 64 624c0-123.136 93.12-223.488 212.608-237.248A239.81 239.81 0 0 1 512 192a239.87 239.87 0 0 1 235.456 194.752c119.488 13.76 212.48 114.112 212.48 237.248a240 240 0 0 1-240 240c-5.376 0-10.56-1.28-16-1.6v1.6z"
                            />
                          </svg>
                        </label>
                        <input
                          name="file"
                          multiple
                          id="file"
                          type="file"
                          placeholder="Upload Image or Audio file"
                          style={{ display: "none" }}
                          onInput={this.updateFiles}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        padding: "10px",
                        display: "flex",
                        alignItems: "center",
                        overflowY: "auto",
                        border: "1px dashed #ccc",
                      }}
                    >
                      {this.state.files
                        .filter((file) => file.type !== "audio/mpeg")
                        .map((image, key) => (
                          <div key={key} style={{ position: "relative" }}>
                            <svg
                              onClick={() => {
                                console.log(image)
                                this.removeFile(image.name);
                              }}
                              style={{
                                position: "absolute",
                                top: "2px",
                                right: "10px",
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="1.5em"
                              height="1.5em"
                              viewBox="0 0 32 32"
                            >
                              <path
                                fill="red"
                                d="M16 2C8.2 2 2 8.2 2 16s6.2 14 14 14s14-6.2 14-14S23.8 2 16 2m5.4 21L16 17.6L10.6 23L9 21.4l5.4-5.4L9 10.6L10.6 9l5.4 5.4L21.4 9l1.6 1.6l-5.4 5.4l5.4 5.4z"
                              />
                            </svg>

                            <img
                         
                              src={URL.createObjectURL(image)}
                              style={{
                                width: "100px",
                                height: "100px",
                                marginRight: "10px",
                              }}
                            />
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "10px",
                              }}
                            >
                              <div
                                style={{
                                  width: "80px",
                                  height: "5px",
                                  borderRadius: "10px",
                                }}
                                className="bg-gradient-success"
                              ></div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="currentColor"
                                  fill-rule="evenodd"
                                  d="M12 21a9 9 0 1 0 0-18a9 9 0 0 0 0 18m-.232-5.36l5-6l-1.536-1.28l-4.3 5.159l-2.225-2.226l-1.414 1.414l3 3l.774.774z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </div>
                          </div>
                        ))}
                    </div>

                    <div style={{ border: "1px dashed #ccc", padding: "10px" }}>
                      {this.state.files
                        .filter((file) => file.type === "audio/mpeg")
                        .map((audio, key) => (
                          <div key={key} style={{ position: "relative" }}>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <audio
                                key={key}
                                src={URL.createObjectURL(audio)}
                                controls
                              />
                              <svg
                                onClick={() => {
                                  console.log(audio)
                                  this.removeFile(audio.name);
                                }}
                                style={{
                                  position: "absolute",
                                  top: "2px",
                                  right: "10px",
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="1.5em"
                                height="1.5em"
                                viewBox="0 0 32 32"
                              >
                                <path
                                  fill="red"
                                  d="M16 2C8.2 2 2 8.2 2 16s6.2 14 14 14s14-6.2 14-14S23.8 2 16 2m5.4 21L16 17.6L10.6 23L9 21.4l5.4-5.4L9 10.6L10.6 9l5.4 5.4L21.4 9l1.6 1.6l-5.4 5.4l5.4 5.4z"
                                />
                              </svg>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "10px",
                              }}
                            >
                              <div
                                style={{
                                  width: "80px",
                                  height: "5px",
                                  borderRadius: "10px",
                                }}
                                className="bg-gradient-success"
                              ></div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="currentColor"
                                  fill-rule="evenodd"
                                  d="M12 21a9 9 0 1 0 0-18a9 9 0 0 0 0 18m-.232-5.36l5-6l-1.536-1.28l-4.3 5.159l-2.225-2.226l-1.414 1.414l3 3l.774.774z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </div>
                          </div>
                        ))}
                    </div>

                    <div className="text-center">
                      {this.state.word_loading ? (
                        <button
                          className="btn w-100 btn-gradient-info btn-sm mb-2"
                          type="button"
                          disabled
                        >
                          <span
                            className="spinner-border spinner-border-sm me-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Loading...
                        </button>
                      ) : (
                        <button
                          onClick={this.submitWord}
                          type="button"
                          className="btn bg-gradient-success w-100 mt-4 mb-0 sl-upload-word"
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function WordUpdate(props) {
  let updateFiles = useFiles((state) => state.updateFiles);
  let files = useFiles((state) => state.files);

  let { wordid } = useParams();
  return (
    <WordUploadWindow
      {...props}
      word_id={wordid}
      files={files}
      updateFiles={updateFiles}
    />
  );
}

export default WordUpdate;

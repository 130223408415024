import "./custom.css";
import { makeRequest } from "../utils/utils";
import { API_URL } from "../utils/utils";
import { FaPlay } from "react-icons/fa";
import {toast} from 'react-toastify';


export default function ImageCarousel({ images }) {
  console.log(images);


  function playAudio(audioUrl) {
    // Create a new Audio object with the provided URL
    const audio = new Audio(audioUrl);
  
    // Play the audio
    audio.play().then(() => {
      console.log("Audio is playing");
    }).catch(error => {
      console.error("Error playing audio:", error);
    });
  
    // Optional: Add event listeners for additional functionality
    audio.addEventListener('ended', () => {
      console.log("Audio has ended");
    });
  
    audio.addEventListener('error', (error) => {
      console.error("Error occurred while playing audio:", error);
    });
  }
  
  const deleteDocument = (event) => {
    console.log("deleting file now");
    let _this = this;
    let parent = event.currentTarget.parentElement

    let document_id = event.currentTarget.getAttribute("data-doc-id");
    makeRequest(API_URL + "/api/words/delete_file/", {
      method: "DELETE",
      body: JSON.stringify({ file_id: document_id }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.log("handle error here");
        } else {
          res.json().then((data) => {
            toast.success("File deleted" );
            parent.classList.add('collapse');
          });
        }
      })
      .catch((error) => {
        toast.error(error.message );
      });
  };
  return (
    <div className="row  align-items-center my-2">
      {images.map((image, index) => {
        if (image.url.endsWith(".mp3") | image.url.endsWith(".aac")) {
          return (
            <div
            key={index}
            className='col-12 col-lg-3 col-md-4'
          >
            <div className="position-relative">
              <img
                style={{ height: "200px", width: '100%' }}
                src={'/images/speaker.jpeg'}
                className="d-block "
                alt={`Slide ${index + 1}`}
              />
              <button
                type="button"
                className="btn btn-close-custom position-absolute top-10 start-50 translate-middle"
                aria-label="Close"
                data-doc-id={image.id}
                onClick={deleteDocument}
              >
                &times;
              </button>

              <button
                type="button"
                className="btn btn-play-custom "
                aria-label="Close"
                data-doc-id={image.id}
                onClick={function(){playAudio(image.url)}}
              >
                <FaPlay />
              </button>
            </div>
          </div>
          );
        }
        return (
          <div
            key={index}
            className='col-12 col-lg-3 col-md-4'
          >
            <div className="position-relative" style={{margin: '5px'}}>
              <img
                style={{ height: "200px", width: '100%' }}
                src={image.url}
                className="d-block "
                alt={`Slide ${index + 1}`}
              />
              <button
                type="button"
                className="btn btn-close-custom position-absolute top-10 start-50 translate-middle"
                aria-label="Close"
                data-doc-id={image.id}
                onClick={deleteDocument}
              >
                &times;
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
}

import React from 'react'
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import useAlert from '../context/alertContext';

export default function SimpleSnackbar(props) {
  const updateAlert = useAlert(state => state.updateAlert)
  const message = useAlert(state => state.message)
  const error = useAlert(state => state.error)
//console.log('This message is coming from the snackbar')
//console.log(error);





  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    updateAlert({error: false, message: ''})
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        X
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Snackbar
        open={error}
        autoHideDuration={7000}
        onClose={handleClose}
        message={message}
        action={action}
      />
    </div>
  );
}